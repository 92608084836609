<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Quản lí cấu hình step cho roadmap {{ roadmap_name }}
      </h1>
    </div>

    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogStep"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm step</span>
      </v-btn>
      <v-btn
        rounded
        color="cyan"
        class="mb-5 ml-5"
        x-small
        large
        @click="openDialogSort"
      >
        <v-icon color="white" dark>mdi-sort</v-icon
        ><span style="color: white">Sắp xếp</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">title</th>
                    <th scope="col">Description</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(step, i) in roadmap_steps">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ step.title }}
                        </p>
                      </td>
                      <td>
                        <p
                          style="
                            max-width: max-content;
                            max-height: 300px;
                            overflow: hidden;
                          "
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          v-html="step.description"
                        ></p>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-success btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="editStep(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteStep(step.id)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog
        v-model="dialogStep"
        max-width="1400px"
        scrollable
        persistent
        :retain-focus="false"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form === 'add'">Thêm step</span>
              <span class="headline" v-else>Sửa step</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogStep = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Title:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><v-text-field
                        dense
                        v-model="title_input"
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="mt-2">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Description:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0" id="image-banner">
                      <Editor v-model="description_input"></Editor>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEditStep(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEditStep(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogStep = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialogSort" scrollable persistent max-width="600px">
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sắp xếp step</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSort = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <draggable :list="data_draggable">
                    <ul
                      class="list-unstyled drag-css"
                      v-for="(step, i) in data_draggable"
                      :key="i"
                    >
                      <b-media tag="li">
                        <h5 class="mt-0 mb-1" style="color: #3699ff">
                          {{ step.title }}
                        </h5>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" outlined text @click="sortStep"
              >Update</v-btn
            >
            <v-btn color="red darken-1" text @click="dialogSort = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import draggable from "vuedraggable";
import Editor from "../../../components/ckeditor5/Editor";
import Swal from "sweetalert2";

export default {
  name: "RoadmapStepList",
  components: {
    Editor,
    draggable,
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      roadmap_name: "",
      roadmap_steps: [],
      roadmap_step: {},
      title_input: "",
      description_input: "",
      type_form: null,
      roadmap_step_id: null,
      dialogStep: false,
      data_draggable: [],
      dialogSort: false,
    };
  },
  computed: {
    roadmap_id: function () {
      return this.$route.params.id;
    },
  },
  created() {
    this.getAllRoadmapStep();
  },
  methods: {
    async getAllRoadmapStep() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get(
          "prep-app/roadmap/" + this.roadmap_id + "/step"
        );
        if (res.status === 200) {
          vm.roadmap_name = res.data.roadmap_name;
          vm.roadmap_steps = res.data.data;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    openDiaLogStep() {
      this.type_form = "add";
      this.title_input = "";
      this.description_input = "";
      this.dialogStep = true;
    },
    validateData() {
      let flat = true;
      if (this.title_input === "" || this.title_input == null) {
        this.$toasted.error("Chưa nhập title !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      return flat;
    },
    async btnAddOrEditStep(type) {
      let validateQues = this.validateData();
      if (!validateQues) {
        return;
      }
      let vm = this;
      let data = {
        title: this.title_input,
      };
      if (!(this.description_input == null || this.description_input === ""))
        data.description = this.description_input;
      this.is_call_api = true;
      if (type === 1) {
        data.offset = this.roadmap_steps.length + 1;
        await ApiService.post(
          "prep-app/roadmap/" + this.roadmap_id + "/step",
          data
        )
          .then(function (res) {
            if (res.status === 200) {
              vm.getAllRoadmapStep();
              vm.$toasted.success("Tạo thành công !", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.is_call_api = true;
              vm.dialogStep = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = true;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        await ApiService.put(
          "prep-app/roadmap/" +
            this.roadmap_id +
            "/step/" +
            this.roadmap_step_id,
          data
        )
          .then(function (res) {
            if (res.status === 200) {
              vm.getAllRoadmapStep();
              vm.$toasted.success("Sửa thành công !", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.is_call_api = true;
              vm.dialogStep = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = true;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    editStep(index) {
      this.type_form = "update";
      let data = this.roadmap_steps[index];
      this.roadmap_step_id = data.id;
      this.title_input = data.title;
      this.description_input = data.description == null ? "" : data.description;
      this.dialogStep = true;
    },
    deleteBanner(step_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete(
            "prep-app/roadmap/" + this.roadmap_id + "/step/" + step_id
          )
            .then(function (res) {
              if (res.status === 200) {
                vm.getAllRoadmapStep();
                vm.$toasted.success("Sửa thành công !", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    openDialogSort() {
      this.data_draggable = JSON.parse(JSON.stringify(this.roadmap_steps));
      this.dialogSort = true;
    },
    sortStep() {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want save?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          let items = this.data_draggable.map((item, index) => {
            return {
              step_id: item.id,
              offset: index + 1,
            };
          });
          let data = {
            data_sort: items,
          };
          vm.is_call_api = true;
          ApiService.post(
            "prep-app/roadmap/" + this.roadmap_id + "/step/sort",
            data
          )
            .then(function (res) {
              if (res.status === 200) {
                vm.getAllRoadmapStep();
                vm.$toasted.success("Sắp xếp thành công !", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.is_call_api = false;
                vm.dialogSort = false;
              }
            })
            .catch(function (error) {
              vm.is_call_api = false;
              console.log(error);
            });
        }
      });
    },
    deleteStep(step_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete(
            "prep-app/roadmap/" + this.roadmap_id + "/step/" + step_id
          )
            .then(function (res) {
              if (res.status === 200) {
                vm.getAllRoadmapStep();
                vm.$toasted.success("Xóa thành công !", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
</style>
